<template>
	<div class="page-container page_regi">
		<!-- <RegiHead /> -->
		<div class="regi-bd change_bd">
			<div class="regi_name">{{$t('acTxt10')}}</div>
			<el-form ref="form" :rules="rules" class="regi-from" :model="form">
				<el-form-item prop="newPassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt5')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.newPassword" placeholder="" type="password">
							</el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="checkPassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt6')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.checkPassword" placeholder="" type="password">
							</el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="code" :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }]">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt11')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<el-input v-model="form.code" placeholder=""></el-input>
							<el-button class="btn btn_yzm" @click="sendEmail" :disabled="disabled">
								<span v-if="disabled">{{count}} S</span>
								<span v-if="!disabled">{{$t('code2')}}</span>
							</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<div class="regi_group chan_btn flex flex-ac flex-between">
						<el-button class="btn btn_reset"   size="large" type="primary" @click="resetForm('form')">{{$t('reset')}}
						</el-button>
						<el-button class="btn"   size="large" type="primary" @click="submitForm('form')">{{$t('save')}}</el-button>
					</div>
				</el-form-item>
			</el-form>
			<!-- <div class="regi_tips">Copyright ©2024 Alcex All rights reserved.</div> -->
		</div>
	</div>
</template>

<script>
	import {
		transactionCode,
		resetTransactionPass
	} from '@/api/api/user'
	import RegiHead from '@/components/RegiHead.vue'
	export default {
		components: {
			RegiHead,
		},
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error(this.$t("chtip6")));
				} else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
					callback(new Error(this.$t("chtip7")));
				} else {
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error(this.$t("chtip7")));
				} else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
					callback(new Error(this.$t("chtip7")));
				} else if (value !== this.form.newPassword) {
					callback(new Error(this.$t("chtip7")));
				} else {
					callback();
				}
			};
			return {
				form: {},
				rules: {
					oldPassword: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t('chtip'),
						trigger: 'blur'
					}],
					newPassword: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t("chtip6"),
						trigger: "blur"
					}, {
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPassword: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t("chtip7"),
						trigger: "blur"
					}, {
						validator: validatePass2,
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: this.$t("chTxt3"),
						trigger: "blur"
					}]
				},
				count: 60,
				disabled: false
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						resetTransactionPass({
							newPassword: this.form.newPassword,
							code: this.form.code
						}).then(res => {
							if (res.code == 0) {
								this.$router.push('/admin')
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			sendEmail() {
				transactionCode().then(res => {
					if (res.code == 0) {
						this.disabled = true
						let interval = window.setInterval(function() {
							if (this.count-- <= 0) {
								this.count = 60;
								this.disabled = false;
								window.clearInterval(interval);
							}
						}, 1000);
					} else {
						this.$message.error(res.message);
					}
				})
			}
		}
	}
</script>
